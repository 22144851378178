import React, { useState, useRef, useEffect } from "react";

import useTranslations from "./useTranslations";
import LocalizedLink from "../components/localizedLink";
import IconButton from "../components/iconButton";

import Cross from "../images/svg/icon-cross.svg";

export default function Navigation() {
  const [isActive, setActive] = useState(false);
  const nav = useRef();

  const {
    openNavigation,
    close,
    discover,
    about,
    blog,
    impressum,
    chatbot,
  } = useTranslations();

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", closeOnClickOutside);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", closeOnClickOutside);
    };
  }, []);

  function closeOnClickOutside(e) {
    if (nav.current.contains(e.target)) {
      return;
    }

    setActive(false);
  }

  return (
    <div className="nav">
      <button
        className={
          isActive ? "nav__hamburger nav__hamburger--active" : "nav__hamburger"
        }
        type="button"
        title={openNavigation}
        aria-label={openNavigation}
        aria-controls="navigation"
        onClick={() => setActive(!isActive)}
      >
        <span className="nav__hamburger-box">
          <span className="nav__hamburger-inner"></span>
        </span>
      </button>

      <nav
        id="navigation"
        ref={nav}
        className={isActive ? "nav__menu nav__menu--active" : "nav__menu"}
      >
        <div className="nav__close">
          <IconButton
            Icon={Cross}
            label={close}
            style="transparent"
            method={() => setActive(false)}
            controls="navigation-menu"
            isExpanded={isActive}
            className="nav__close-button"
          />
        </div>

        <ul className="navigation__list" id="navigation-menu">
          <li>
            <LocalizedLink to="/discover" className="nav__item">
              {discover}
            </LocalizedLink>
          </li>
          <li>
            <LocalizedLink to="/about" className="nav__item">
              {about}
            </LocalizedLink>
          </li>
          {/*
          <li>
            <LocalizedLink to="/blog" className="nav__item">
              {blog}
            </LocalizedLink>
          </li>*/}
          <li>
            <LocalizedLink to="/chatbot" className="nav__item">
              {chatbot}
            </LocalizedLink>
          </li>
          <li>
            <LocalizedLink to="/impressum" className="nav__item">
              {impressum}
            </LocalizedLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}
