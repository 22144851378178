import React, { useState, useEffect } from "react";

import IconButton from "../iconButton";
import useTranslations from "./../useTranslations";

import Eye from "../../images/svg/icon-eye2.svg";
import Moon from "../../images/svg/icon-moon.svg";
import Contrast from "../../images/svg/icon-bright.svg";
import Letter from "../../images/svg/icon-a.svg";
import Minus from "../../images/svg/icon-minus.svg";
import Plus from "../../images/svg/icon-plus.svg";

export default function AccessibilityPanel() {
  const [fontAttr, changeFontAttr] = useState("");
  const [modeAttr, changeModeAttr] = useState("");
  const [fontSize, changeFontSize] = useState(100);

  const {
    standardContrast,
    nightView,
    blackWhite,
    blackYellow,
    yellowBlack,
    smallerFont,
    biggerFont,
    defaultFont,
  } = useTranslations();

  useEffect(() => {
    switchFont(fontSize);
  });

  function switchFont(newValue) {
    if (fontAttr) {
      document.documentElement.removeAttribute("data-font");
    }

    if (newValue) {
      document.documentElement.setAttribute("data-font", newValue);
    }
    changeFontAttr(newValue);
  }

  function switchMode(newValue) {
    if (!newValue) {
      document.body.setAttribute("data-mode", "hello");
    } else if (newValue) {
      document.body.setAttribute("data-mode", newValue);
      changeModeAttr(newValue);
    } else {
      document.body.setAttribute("data-mode", modeAttr);
    }
  }

  function increaseFont() {
    if (fontSize < 130) {
      changeFontSize(fontSize + 10);
    }
  }

  function decreaseFont() {
    if (fontSize > 70) {
      changeFontSize(fontSize - 10);
    }
  }

  return (
    <div className="accessibility-panel">
      <ul className="accessibility-panel__list">
        <li>
          <IconButton
            Icon={Eye}
            label={blackWhite}
            method={() => switchMode("bw")}
            isActive={modeAttr === "bw"}
            className="accessibility-panel__button accessibility-panel__button--bw"
          />
        </li>
        <li>
          <IconButton
            Icon={Eye}
            label={blackYellow}
            method={() => switchMode("by")}
            isActive={modeAttr === "by"}
            className="accessibility-panel__button accessibility-panel__button--by"
          />
        </li>
        <li>
          <IconButton
            Icon={Eye}
            label={yellowBlack}
            method={() => switchMode("yb")}
            isActive={modeAttr === "yb"}
            className="accessibility-panel__button accessibility-panel__button--yb"
          />
        </li>
        <li>
          <IconButton
            Icon={Moon}
            label={nightView}
            method={() => switchMode("night")}
            isActive={modeAttr === "night"}
            className="accessibility-panel__button accessibility-panel__button--night"
          />
        </li>
        <li>
          <IconButton
            Icon={Contrast}
            label={standardContrast}
            method={() => switchMode("")}
            isActive={modeAttr === ""}
            className="accessibility-panel__button"
          />
        </li>
        <li>
          <IconButton
            Icon={Letter}
            label={defaultFont}
            method={() => changeFontSize(100)}
            className="accessibility-panel__button"
          />
        </li>
        <li>
          <IconButton
            Icon={Minus}
            label={smallerFont}
            method={decreaseFont}
            className="accessibility-panel__button"
          />
        </li>
        <li>
          <IconButton
            Icon={Plus}
            label={biggerFont}
            method={increaseFont}
            className="accessibility-panel__button"
          />
        </li>
      </ul>
    </div>
  );
}
