import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { LocaleContext } from "./layout";

const useTranslations = () => {
  const { locale } = React.useContext(LocaleContext);
  // Query the JSON files in <rootDir>/i18n/translations
  const data = useStaticQuery(query);

  // Only return translations for the current locale
  const translations = data.allJson.edges.filter(
    (item) => item.node.locale === locale
  )[0].node;

  return translations;
};

export default useTranslations;

const query = graphql`
  query useTranslations {
    allJson {
      edges {
        node {
          locale
          homepage
          about
          discover
          blog
          impressum
          chatbot
          openNavigation
          openLanguageSelector
          standardContrast
          nightView
          blackWhite
          blackYellow
          yellowBlack
          smallerFont
          biggerFont
          defaultFont
          displayHashtagText
          displayWork
          showDescription
          showLinks
          showNextWork
          showPreviousWork
          close
          video
          audio
          gallery
          photo
          map
          pdf
          text
          mix
          notFoundPage
          notFoundTitle
          notFoundText
          playVideo
          playAudio
          showImages
          showMap
          downloadPdf
          readText
        }
      }
    }
  }
`;
