import React from "react";

export default function IconButton({
  Icon,
  label,
  method,
  className,
  isActive,
  isExpanded,
  controls,
  numbering,
}) {
  return (
    <button
      className={`${className} ${isActive ? "is-active" : ""} icon-button`}
      type="button"
      onClick={method}
      title={label}
      aria-label={label}
      aria-expanded={isExpanded}
      aria-controls={controls}
    >
      <span
        className={
          numbering
            ? `icon-button__svg icon-button__with-number`
            : `icon-button__svg`
        }
      >
        <Icon />
        {numbering && <span className="icon-button__number">{numbering}</span>}
      </span>
    </button>
  );
}
