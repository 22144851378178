// Use a little helper function to remove trailing slashes from paths
exports.removeTrailingSlash = (path) =>
  path === `/` ? path : path.replace(/\/$/, ``);

exports.localizedSlug = ({ isDefault, locale, slug }) =>
  isDefault ? `${slug}` : `/${locale}/${slug}`;

// From lodash:
// https://github.com/lodash/lodash/blob/750067f42d3aa5f927604ece2c6df0ff2b2e9d72/findKey.js
exports.findKey = (object, predicate) => {
  let result;
  if (object == null) {
    return result;
  }
  Object.keys(object).some((key) => {
    const value = object[key];
    if (predicate(value, key, object)) {
      result = key;
      return true;
    }
    return false;
  });
  return result;
};

exports.memoize = (func) => {
  // Create a new cache, just for this function
  let cache = new Map();

  const memoized = function (...args) {
    // Use the first argument as the cache key.
    // If your function takes multiple args, you may
    // want to come up with a more complex scheme
    let key = args[0];

    // Return the cached value if one exists
    if (cache.has(key)) {
      return cache.get(key);
    }

    // Otherwise, compute the result and save it
    // before returning it.
    let result = func.apply(this, args);
    cache.set(key, result);
    return result;
  };

  return memoized;
};
