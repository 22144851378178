import React from "react";
import { Link } from "gatsby";
import { LocaleContext } from "./layout";
import locales from "../i18n/config";

// Use the globally available context to choose the right path
export default function LocalizedLink({ to, ...props }) {
  const { locale } = React.useContext(LocaleContext);

  const isIndex = to === `/`;
  const path = locales[locale].default
    ? to
    : `/${locales[locale].path}${isIndex ? `` : `${to}`}`;

  return <Link {...props} to={path} />;
}
