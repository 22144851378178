import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";

import { LocaleContext } from "./layout";
import useTranslations from "./useTranslations";
import locales from "../i18n/config";
import IconButton from "./iconButton";

import Globe from "../images/svg/icon-globe.svg";
import Close from "../images/svg/icon-close.svg";

import { removeTrailingSlash } from "../utils/gatsby-node-helpers";

export default function LanguageSelector({ baseSlug }) {
  const [isOpen, openPanel] = useState(false);
  const { isEinfacheSprache, setEinfache } = React.useContext(LocaleContext);

  const langSelector = useRef();

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", closeOnClickOutside);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", closeOnClickOutside);
    };
  }, []);

  const { close, openLanguageSelector } = useTranslations();

  function closeOnClickOutside(e) {
    if (langSelector.current.contains(e.target)) {
      return;
    }

    openPanel(false);
  }

  function onSwitchLocale() {
    setEinfache(false);
    openPanel(false);
  }

  function onEinfacheSpracheSet() {
    setEinfache(true);
    openPanel(false);
  }

  return (
    <div className="language-selector">
      <IconButton
        Icon={isOpen ? Close : Globe}
        label={isOpen ? close : openLanguageSelector}
        method={() => openPanel(!isOpen)}
        isActive={isOpen}
        isExpanded={isOpen}
        className="language-selector__button"
        controls="language-selector"
      />
      <ul
        id="language-selector"
        ref={langSelector}
        className={
          isOpen
            ? "language-selector__list is-active"
            : "language-selector__list"
        }
      >
        {Object.keys(locales).map((lang) => {
          const locale = locales[lang].default ? "" : `/${locales[lang].path}`;
          return (
            <li className="language-selector__item" key={lang}>
              <Link
                className="language-selector__lang"
                to={removeTrailingSlash(`${locale}${baseSlug}`)}
                activeClassName="language-selector__lang--active"
                onClick={() => onSwitchLocale()}
              >
                {locales[lang].name}
              </Link>
            </li>
          );
        })}
        <li className="language-selector__item" key="einfache-sprache">
          <Link
            className={`language-selector__lang language-selector__einfache ${
              isEinfacheSprache ? "language-selector__lang--active" : ""
            }`}
            to={removeTrailingSlash(`${baseSlug}`)}
            onClick={() => onEinfacheSpracheSet()}
          >
            Einfache Sprache
          </Link>
        </li>
      </ul>
    </div>
  );
}
