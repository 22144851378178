import React from "react";

import AccessibilityPanel from "./acessibility/accessibilityPanel";
import LanguageSelector from "./languageSelector";
import Navigation from "./navigation";
import LocalizedLink from "./localizedLink";
import useTranslations from "./useTranslations";

import Logo from "../images/svg/logo.svg";

export default function Header({ ...props }) {
  const { homepage } = useTranslations();

  return (
    <header className="header">
      <div className="header__top">
        <div className="container">
          <div className="row header__top-row">
            <LanguageSelector baseSlug={props.baseSlug} />
            <AccessibilityPanel />
          </div>
        </div>
      </div>

      <div className="header__bottom">
        <div className="container">
          <div className="row header__bottom-row">
            <Navigation />

            <LocalizedLink to="/" aria-label={homepage}>
              <Logo className="header__logo" />
            </LocalizedLink>
          </div>
        </div>
      </div>
    </header>
  );
}
