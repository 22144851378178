import React, { useState } from "react";
import Header from "./header";
import Footer from "./footer";

const LocaleContext = React.createContext();

const Layout = ({ children, pageContext: { locale, baseSlug }, ...props }) => {
  const [isEinfacheSprache, setEinfacheSprache] = useState(false);

  return (
    <LocaleContext.Provider
      value={{
        url: props.location.href,
        locale,
        isEinfacheSprache,
        setEinfache: (payload) => setEinfacheSprache(payload),
      }}
    >
      <div className="wrapper">
        <Header baseSlug={baseSlug} />
        {children}
        <Footer />
      </div>
    </LocaleContext.Provider>
  );
};

export { Layout, LocaleContext };
